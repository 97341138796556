import { getPlat, getToken } from '@/utils/auth';
import { apiClient, baseClient } from "@/utils/request"
import { fetchEventSource } from '@microsoft/fetch-event-source';
import { FetchEventSourceInit } from '@microsoft/fetch-event-source/lib/cjs/fetch';
import { API } from "./typings"

// 提交问题
export const submitQuestion = (data: any, Id: string, sessionId: string) => {
  // console.log(sessionId, "session_id")
  if (getPlat() === "tyj") {
    return apiClient.post<API.ApiResponse<any>>("/chat/personsStream", {
      data: { messages: data, message_id: Id, session_id: sessionId },
    })
  } else if (getPlat() === "xyt") {
    return apiClient.post<API.ApiResponse<any>>("/chat/insStream", {
      data: { messages: data, message_id: Id, session_id: sessionId },
    })
  } else if (
    getPlat() === "ghmp" ||
    getPlat() === "zbmp" ||
    getPlat() === "zsymp"
  ) {
    return apiClient.post<API.ApiResponse<any>>("/chat/mpStream", {
      data: { messages: data, message_id: Id, session_id: sessionId },
    })
  } else if (getPlat() === "kyf") {
    return apiClient.post<API.ApiResponse<any>>("/chat/kfStream", {
      data: { messages: data, message_id: Id, session_id: sessionId },
    })
  } else {
    return apiClient.post<API.ApiResponse<any>>("/chat/stream", {
      data: { messages: data, message_id: Id, session_id: sessionId },
    })
  }
}
// 获取消息

export const chatStream = (Id: string) => {
  if (getPlat() === "tyj") {
    return apiClient.put<API.ApiResponse<any>>("/chat/personsStream", {
      data: { message_id: Id },
    })
  } else if (getPlat() === "xyt") {
    return apiClient.put<API.ApiResponse<any>>("/chat/insStream", {
      data: { message_id: Id },
    })
  } else if (
    getPlat() === "ghmp" ||
    getPlat() === "zbmp" ||
    getPlat() === "zsymp"
  ) {
    return apiClient.put<API.ApiResponse<any>>("/chat/mpStream", {
      data: { message_id: Id },
    })
  } else if (getPlat() === "kyf") {
    return apiClient.put<API.ApiResponse<any>>("/chat/kfStream", {
      data: { message_id: Id },
    })
  } else {
    return apiClient.put<API.ApiResponse<any>>("/chat/stream", {
      data: { message_id: Id },
    })
  }
}

export const eventSource = (url: string, options: FetchEventSourceInit) => {
  return fetchEventSource(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Token: getToken() || ''
    },
   ...options
  });
}


export const sendMessageByDeepSeek = (options: FetchEventSourceInit) => {
  // 区分平台
  let url = '/api/deepseek/stream'
  // 同益家
  if (getPlat() === "tyj") {
    url = '/api/chat/personsStream'
    // 馨益通
  } else if (getPlat() === "xyt") {
    url = '/api/chat/insStream'
  } else if (
    getPlat() === "ghmp" ||
    getPlat() === "zbmp" ||
    getPlat() === "zsymp"
  ) {
    url = '/api/chat/mpStream'
    // 康益复
  } else if (getPlat() === "kyf") {
    url = '/api/chat/kfStream'
  }
  return eventSource(url, {
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      Token: getToken() || ''
    },
    ...options
  })
}

//获取可发送消息数
export const chatCountNumber = () => {
  if (getPlat() === "ghmp" || getPlat() === "zbmp" || getPlat() === "zsymp") {
    return apiClient.get<API.ApiResponse<any>>("/chat/mpStream", {})
  } else {
    return apiClient.get<API.ApiResponse<any>>("/chat/personsStream", {})
  }
}

//获取公众号OpenId
export const chatWxOpenId = (data: any) => {
  return apiClient.post<API.ApiResponse<any>>("/mp/login", { data })
}

//获取分享签名
export const chatWxSignature = (data: any) => {
  return apiClient.post<API.ApiResponse<any>>("/mp/signature", { data })
}
//分享成功获得提问数量
export const chatWxMpShare = () => {
  return apiClient.post<API.ApiResponse<any>>("/mp/share", {})
}
//分享成功获得提问数量
export const chatWxZBMpShare = () => {
  return apiClient.post<API.ApiResponse<any>>("/chat/mpShare", {})
}

//百度获取token
// export const postBaiduToken = () => {
//   return baseClient.post<API.ApiResponse<any>>(
//     "https://aip.baidubce.com/oauth/2.0/token?client_id=34424292&client_secret=iTHpLHMCOFFvjXtdRHFrYWiMvKw0jHHW&grant_type=client_credentials",
//     { mode: "no-cors" }
//   )
// }

export const postChatTextToMedia = (Id: string) => {
  return apiClient.post<API.ApiResponse<any>>("/chat/text2mp3", {
    data: { message_id: Id },
  })
}
