import { useStore } from '@/store/chat';
import React from 'react';
import { SpinLoading } from 'antd-mobile';
import Chat, { Bubble, Button } from '@chatui/core';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkBreaks from 'remark-breaks';
import styles from './index.module.less';
import '@chatui/core/es/styles/index.less';
import '@chatui/core/dist/index.css';
// import 'github-markdown-css';

const GuoHuaChat = () => {
	const {currentSession, sendMessageStream, cancelMessage, isPending} = useStore();
	const messages = currentSession ? currentSession.chats : [];

	async function handleSend(type: string, val: string) {
		if (isPending) {
			return;
		}
		const text = val.trim();
		if (type === 'text' && text) {
			await sendMessageStream(text);
		}
	}

	function renderMessageContent(msg: any) {
		const {content, type, position} = msg;
		if (type === 'loading') {
			return (
				<div>
					<Bubble content={<SpinLoading style={{width: 20, height: 20}}/>}/>
					<Button size="sm" onClick={() => cancelMessage(msg._id)} style={{marginTop: 8}}>
						取消
					</Button>
				</div>
			);
		}
		return (
			<div>
				<Bubble
					content={
						position === 'left' ? (<div className={'markdown-body'}>
							<ReactMarkdown
								remarkPlugins={[remarkGfm, remarkBreaks]}
							>
								{content.text}
							</ReactMarkdown>
						</div>) : content.text
					}
				/>
				{msg.isPending && position === 'left' && (
					<Button size="sm" onClick={() => cancelMessage(msg._id)} style={{marginTop: 8}}>
						取消
					</Button>
				)}
			</div>
		);
	}

	return (
		<div className={styles.chatGptTop}>
			<Chat
				messages={messages}
				// recorder={{
				// 	canRecord: true,
				// 	onStart() {
				// 		console.log('开始录音');
				// 		// RecordStart();
				// 	},
				// 	onEnd() {
				// 		console.log('停止录音');
				// 		// RecordStop();
				// 	},
				// 	onCancel() {
				// 		console.log('取消录音');
				// 	},
				// }}
				renderMessageContent={renderMessageContent}
				onSend={handleSend}
			></Chat>
			{/*<audio onEnded={handleAudioEnded} ref={audioRef} style={{ display: 'none', }} controls />*/}
			{/* <div style={{ position: "absolute", zIndex: 100, top: 0 }}>
      <audio ref={audioRef} controls />
      <Button onClick={playAudio}>play</Button>

    </div> */}
		</div>
	);
};

export default GuoHuaChat;
