import { sendMessageByDeepSeek } from '@/services/api';
import { create } from 'zustand';
import { v4 as uuidv4 } from 'uuid';
import { Toast } from 'antd-mobile';


type MessageId = string;

interface User {
	avatar?: string;
	name?: string;
	url?: string;

	[k: string]: any;
}

interface IMessage {
	/**
	 * 唯一ID
	 */
	_id: MessageId;
	/**
	 * 消息类型
	 */
	type: string;
	/**
	 * 消息内容
	 */
	content?: any;
	/**
	 * 消息发送者信息
	 */
	user?: User;
	/**
	 * 消息位置
	 */
	position?: 'left' | 'right' | 'center' | 'pop';
	isPending?: boolean;
}

interface Session {
	id: string;
	title: string;
	chats: IMessage[];
}

interface State {
	signal: AbortController | null;
	isPending: boolean;
	sessions: Session[];
	currentSession: Session | null | undefined;
	createSession: () => void;
	deleteSession: (id: string) => void;
	setCurrentSession: (id: string) => void;
	addChatMessage: (sessionId: string, chatMessage: IMessage) => void;
	sendMessageStream: (text: string) => Promise<void>,
	updateChatMessage: (sessionId: string, messageId: MessageId, updatedMessage: IMessage) => void
	cancelMessage: (messageId: string) => void
}

const userAvatar = 'https://guohua-institution.oss-cn-beijing.aliyuncs.com/header-chat.svg';
const botAvatar = 'https://guohua-institution.oss-cn-beijing.aliyuncs.com/ic_chatgpt_active.svg';

const initSession = {
	id: uuidv4(),
	title: 'New Session',
	chats: [
		{
			_id: uuidv4(),
			type: 'text',
			content: {text: '您好，欢迎使用AI智询功能，现在您可以向我提出有关助残服务的相关问题了。 ', icon: false},
			user: {avatar: botAvatar},
		},
	],
};

export const useStore = create<State>()(
		(set, get) => ({
			signal: null,
			isPending: false,
			sessions: [initSession],
			currentSession: initSession,
			createSession() {
				// 新增会话逻辑
				const newSession: Session = {
					id: uuidv4(),
					title: 'New Session',
					chats: [],
				};
				set((state) => ({
					sessions: [
						...state.sessions,
						newSession,
					],
					currentSession: newSession,
				}));
			},
			deleteSession(id: string) {
				// 删除会话逻辑
				set((state) => ({
					sessions: state.sessions.filter(session => session.id !== id),
				}));
			},
			setCurrentSession(id: string) {
				// 设置当前会话逻辑
				const session = get().sessions.find(session => session.id === id);
				set({currentSession: session});
			},

			addChatMessage(sessionId: string, chatMessage: IMessage) {
				// 新增chat messgae
				const state = get();
				const updatedSessions = state.sessions.map(session => {
					if (session.id === sessionId) {
						let title = session.title;
						if (session.chats.length === 0) {
							title = chatMessage.content.text.substring(0, 50);
						}
						return {
							...session,
							chats: [...session.chats, chatMessage],
							title,
						};
					}
					return session;
				});
				let updateCurrentSession = state.currentSession;
				if (updateCurrentSession && updateCurrentSession.id === sessionId) {
					let title = updateCurrentSession.title;
					if (updateCurrentSession.chats.length === 0) {
						title = chatMessage.content.text.substring(0, 50);
					}
					updateCurrentSession = {
						...updateCurrentSession,
						chats: [...updateCurrentSession.chats, chatMessage],
						title,
					};
				}
				set({
					sessions: updatedSessions,
					currentSession: updateCurrentSession,
				});
			},

			updateChatMessage(sessionId: string, messageId: MessageId, updatedMessage: IMessage) {
				const sessions = get().sessions;
				const updatedSessions = sessions.map(session => {
					if (session.id === sessionId) {
						const chats = session.chats.map(chat => {
							if (chat._id === messageId) {
								return {...updatedMessage, _id: chat._id};
							}
							return {...chat};
						});
						return {
							...session,
							chats: [...chats],
						};
					}
					return session;
				});
				set(state => {
					const currentSession = updatedSessions.find(s => s.id === sessionId) || state.currentSession;
					return {
						sessions: updatedSessions,
						currentSession: {...currentSession} as Session,
					};
				});
			},


			cancelMessage(messageId: string) {
				const session = get().currentSession;
				const sessionId = session!.id;

				if (!sessionId) {
					return;
				}

				set({isPending: false});

				const canceledMessage: IMessage | undefined = session?.chats?.find(m => m._id === messageId);

				if (canceledMessage) {
					const realMessage: IMessage = {
						...canceledMessage,
						type: 'text',
						isPending: false,
					};
					get().updateChatMessage(sessionId, messageId, realMessage);
				}
				get().signal?.abort();

			},

			async sendMessageStream(text: string) {
				if (!get().currentSession) {
					get().createSession();
				}
				set({isPending: true});
				const session = get().currentSession;
				const sessionId = session!.id;
				const userMessage: IMessage = {
					_id: uuidv4(),
					type: 'text',
					content: {text},
					position: 'right',
					user: {
						avatar: userAvatar,
					},
				};
				get().addChatMessage(sessionId, userMessage);

				const loadingMessage: IMessage = {
					_id: uuidv4(),
					type: 'loading',
					content: {text: ''},
					position: 'left',
					isPending: true,
					user: {
						avatar: botAvatar,
					},
				};
				get().addChatMessage(sessionId, loadingMessage);

				let resp = '';


				try {

					const ctrl = new AbortController();
					set({signal: ctrl});
					await sendMessageByDeepSeek({
						body: JSON.stringify({
							messages: [
								{
									role: 'user',
									content: text,
								},
							],
							session_id: sessionId,
						}),
						signal: ctrl.signal,
						async onopen(response) {
							if (response.status === 500) {
								const m = await response.text();
								Toast.show({
									icon: 'fail',
									content: m
								})
							}
						},
						onmessage(event) {
							const delta = event.data.replace(/(\\n)+/g, '\n');
							resp += delta;

							const realMessage: IMessage = {
								...loadingMessage,
								type: 'text',
								isPending: true,
								content: {text: resp},
							};
							get().updateChatMessage(sessionId, loadingMessage._id, realMessage);
						},
						onclose() {
							console.log('close');
							get().cancelMessage(loadingMessage._id);
						},
						onerror(err) {
							console.log('error: ', err);
							get().cancelMessage(loadingMessage._id);
							throw err;
						},
					});

				} catch (error) {
					console.error('error: ', error);
				}
			},
		}),
	);